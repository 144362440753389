import $ from "jquery";
import whatInput from "what-input";

window.$ = $;

// import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import "./lib/foundation-explicit-pieces";

// import 'tablesaw/dist/tablesaw.jquery';
import libs from "./lib/dependancies";
window.libs = libs;

$(document).foundation();

libs.AOS.init({
  duration: 800, // values from 0 to 3000, with step 50ms
  delay: 200,
  easing: "ease-in-out", // default easing for AOS animations
});

// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll("svg.inject-me");

// Options
var injectorOptions = {
  evalScripts: "once",
  pngFallback: "assets/png",
};

//open navbar-medium on site load
// new Foundation.OffCanvas($("#navbar-medium")).open();

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  // console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
  $(window).trigger("resize");
  navbar();
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  // console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);


const navbar= () =>{
  // let wrap = $("#main-nav-bar");
  let navBar = $("#nav-bar");
  let content = $("#content");
  // $('#content').css('padding-top', $('#top-info').height());

  let topInfo = $("#info-bar");
  let topAnnouncement = $("#announcement").height() || 0;
  let lastScrollTop = 0;
  let totalHeight = topInfo.height() + topAnnouncement;

  content.css("padding-top", topInfo.height() + "px");

  $("#home-link").focus();

  $(window).on("scroll", function (e) {
    let st = $(this).scrollTop();
    if (st > totalHeight) {
      navBar.addClass("fixed");
    } else {
      navBar.removeClass("fixed");
    }

    if (st > lastScrollTop) {
      // downscroll code
      if (st > totalHeight && st < $(document).height()) {
        topInfo.addClass("fade-out");
      }
    } else {
      // upscroll code
      if (st > totalHeight && st < $(document).height()) {
        topInfo.removeClass("fade-out");
      }
    }
    lastScrollTop = st;
  });
}


// Main Nav fixed on Scroll
$(window).bind("load", function () {
  navbar();
});
