'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.core.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.core.utils';
import 'leaflet';
import 'leaflet-providers';

class Map extends Plugin {
    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, Map.defaults, this.$element.data(), options);
        this._init();
    }

    _init() {
        var id = this.$element[0].id || GetYoDigits(6, 'map');
        this.$element.attr({
            'id': id,
        });
        this.map = L.map(id, {dragging: !L.Browser.mobile}).setView(this.options.mapPosition, this.options.initialZoom);
        // console.log(this.map);
        this.map.scrollWheelZoom.disable();
        // this.map.dragging.disable();
        L.tileLayer.provider(this.options.mapStyle).addTo(this.map);
            console.log(this.options.markers)
        this._create_markers()
    }

    _create_markers() {
        var circle_option = this.options.markerCircle;
        var icon_url = this.options.iconUrl;
        var map = this.map;
        if(this.options.markers && this.options.markers.length > 0){
            this.options.markers.forEach(function(marker){
                draw_marker(map, marker);
            });
        }

        function draw_circle(map, marker) {
            var circle = L.circle(marker[0], {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 0.5,
                radius: 20
            }).addTo(map);
            if(marker[1]){
                circle.bindPopup(marker[1]);
            }
        }

        function draw_marker(map, marker) {
            console.log("icon_url");
            console.log(marker);

            var icon = L.icon({
                iconUrl: marker[2],
                iconSize: [57, 57]
            })
            var iconMarker = L.marker(marker[0], {icon: icon}).addTo(map);
            if(marker[1]){
                iconMarker.bindPopup(marker[1]);
            }
        }
    }
}

Map.defaults = {
    // see (https://leaflet-extras.github.io/leaflet-providers/preview/) for a demo of available map styles
    mapStyle: 'CartoDB.Voyager',
    // see (https://www.gps-coordinates.net) to check latlong of places
    mapPosition: [52.520008, 13.404954],
    initialZoom: 15,
    // provide markers in the format [[[lat, longt], "popup text 1"],[[lat, longt], "popup text 2"]]
    markers: null,
    markerCircle: false,
    iconUrl: "icon_path_here"
}

export { Map };
